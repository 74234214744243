body {
  font: 14px "Century Gothic", Futura, sans-serif;
  margin: 5px;
  background: #4f4f4f;
}

ol,
ul {
  padding-left: 30px;
}

.map-container {
  /* margin-top: 5px; */
  /* height: calc(100vh - 150px); */
  height: 100%;
  /* width: calc(55vw - 10px); */
  flex-grow: 1;
  /* width: 100%; */
}

.sidebar {
  background-color: #0e5700;
  color: #2bff00;
  padding: 6px 12px;
  font-family: monospace;
  z-index: 9999;
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 6px;
  margin-right: 2%;
  border-radius: 4px;
  text-wrap: nowrap;
}

.highcharts-button-normal text {
  color: white !important;
  fill: white !important;
}